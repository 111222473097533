import styled from "styled-components";

export const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  position: absolute;
  left: 10%;
  top: 140px;

  @media (min-width: 1600px) {
    font-size: 5vw;
  }
  @media (min-width: 768px) {
    align-items: flex-start;
    text-align: left;
    width: 400px;
    height: 600px;
    padding-right: 2rem;
    font-size: 10vw;
  }
  @media (max-width: 650px) {
    left: 0;
    top: 0;
  }
`;

export const Heading = styled.h1`
  color: #011638;
  font-size: 120px;
  font-weight: 400;
  letter-spacing: -0.28231rem;
  margin-bottom: 1rem;
  line-height: 80%;

  @media (max-width: 410px) {
    font-size: 23vw;
    width: 90vw;
    text-align: left;
    margin-left: 30px;
    margin-top: 20px;
    position: relative;
    overflow: hidden;

    /* Create a mask for the text */
    mask-image: linear-gradient(
      orange,
      orange 80%,
      transparent 80%,
      transparent
    );

    /* Apply the background color to the entire text */
    color: #011638;

    /* Make sure the text shows through the mask */
    -webkit-background-clip: text;
    background-clip: text;
  }

  @media (max-width: 650px) {
    font-size: 25vw;
    width: 90vw;
    text-align: left;
    margin-left: 30px;
    margin-top: 20px;
    position: relative;
    overflow: hidden;

    /* Create a mask for the text */
    mask-image: linear-gradient(
      orange,
      orange 80%,
      transparent 80%,
      transparent
    );

    /* Apply the background color to the entire text */
    color: #011638;

    /* Make sure the text shows through the mask */
    -webkit-background-clip: text;
    background-clip: text;
  }

  @media (min-width: 1300px) {
    margin-left: 70px;
  }

  @media (min-width: 1400px) {
    font-size: 120px;
    margin-top: 60px;
  }
`;

export const Subtext = styled.h2`
  color: #1e2d46;
  font-family: "axiforma-light";
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.03438rem;
  width: 110%;
  @media (max-width: 650px) {
    font-size: 4vw;
    position: absolute;
    top: 580px;
    text-align: left;
    width: 80%;
  }
  @media (min-width: 1200px) {
    font-size: 17px;
  }
  @media (min-width: 1300px) {
    margin-left: 70px;
  }
`;

export const SubtextTwo = styled.h3`
  color: #1e2d46;
  font-family: "axiforma-light";
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: -15px;
  letter-spacing: 0.03438rem;
  width: 100%;
  @media (max-width: 650px) {
    font-size: 4vw;
    position: absolute;
    top: 690px;
    text-align: left;
    width: 80%;
  }
  @media (min-width: 1200px) {
    font-size: 17px;
  }
  @media (min-width: 1300px) {
    margin-left: 70px;
  }
`;

export const ColorfulDiv = styled.div`
  width: 70vw;
  height: 360px;
  background: #e88565;
  position: absolute;
  right: 50px;
  top: 187px;
  z-index: -2;
  @media (max-width: 650px) {
    width: 70vw;
    height: 170px;
    top: 350px;
  }
`;

export const Image2 = styled.img`
  position: absolute;
  top: 350px;
  right: 45%;
  @media (max-width: 1200px) {
    right: 40%;
  }
  @media (max-width: 1020px) {
    display: none;
  }
  @media (min-width: 1150px) {
    right: 43%;
    top: 370px;
  }
  @media (min-width: 1150px) {
    right: 43%;
    top: 370px;
  }
  @media (min-width: 1300px) {
    right: 35%;
  }
  @media (min-width: 1400px) {
    right: 45%;
    top: 400px;
  }
  @media (min-width: 1600px) {
    right: 50%;
    top: 400px;
  }
`;

export const Image = styled.img`
  width: 39.29175rem;
  height: 39.29175rem;
  position: absolute;
  top: 120px;
  right: 10%;
  object-fit: cover;
  border-radius: 20px;
  background: lightgray 0px -130.313px / 100% 123.688% no-repeat;

  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.07),
    0px 4px 8px 0px rgba(0, 0, 0, 0.07), 0px 14px 14px 0px rgba(0, 0, 0, 0.06),
    0px 32px 19px 0px rgba(0, 0, 0, 0.04), 0px 57px 23px 0px rgba(0, 0, 0, 0.01),
    0px 89px 25px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px inset linear-gradient(45deg, #ffffff, #2d2d2d);

  @media (min-width: 1200px) {
    width: 25rem;
    height: 35rem;
  }
  @media (min-width: 768px) {
    width: 18rem;
    height: 30rem;
  }
  @media (max-width: 650px) {
    width: 50%;
    height: 300px;
    top: 265px;
    left: 20px;
    z-index: 100;
  }
  @media (min-width: 1300px) {
    right: 10%;
    width: 30rem;
    height: 35rem;
  }
`;

export const Italic = styled.span`
  font-family: "CustomFontRegularItalic";
  margin-right: 9px;
`;
