import { Accordion, Typography, AccordionDetails } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { Italic } from "sections/LandingPage/LandingPage.css";
import { BlurredImage } from "coomponents/BluredImage";

const girlImage = require("./../../assets/babeczka2.jpg");

const OfertyContainer = styled.div`
  height: calc(100vh - 80px);
  width: 98vw;
  display: flex;
  margin: 100px 0 150px 0;
  justify-content: center;
  @media (max-width: 650px) {
    height: 100vh;
    margin-top: 120px;
    margin-bottom: 20px;
  }
  @media (max-width: 450px) {
    margin-top: 180px;
  }
  @media (max-height: 800px) {
    margin: 170px 0 250px 0;
  }
`;
const Left = styled.div`
  width: 25%;
  @media (max-height: 750px) and (min-width: 800px) {
    width: 40%;
  }
  @media (max-width: 1000px) {
    width: 30%;
  }
  @media (max-width: 850px) {
    width: 40%;
  }
  @media (max-width: 650px) {
    width: 90%;
  }
`;
const Right = styled.div`
  width: 40%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 7%;
  top: -50px;
  @media (max-height: 800px) {
    top: 0px;
  }
  @media (max-width: 650px) {
    display: none;
  }
`;
const OfertyTitle = styled.p`
  color: #e88565;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  @media (max-width: 650px) {
    font-size: 12vw;
    margin-top: -10px;
  }
`;

const OfertySubtitle = styled.p`
  color: #011638;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  margin-top: -40px;
  @media (max-width: 650px) {
    font-size: 5vw;
    margin-top: -33px;
  }
  @media (max-width: 450px) {
    font-size: 6vw;
  }
`;

const SmallText = styled.div`
  color: #657083;
  font-family: "axiforma-light";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.54px;
  margin-top: -20px;
  @media (max-width: 650px) {
    font-size: 3vw;
    margin-top: 8px;
    margin-bottom: 25px;
  }
  @media (max-width: 450px) {
    font-size: 4vw;
  }
`;

const Devider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a1a1a1;
  margin: 15px 0;
  @media (max-width: 650px) {
    display: none;
  }
`;

const TransformScale = styled.div`
  transform: scale(0.9);
  @media (max-width: 1300px) {
    transform: scale(0.8);
  }
  @media (max-width: 1100px) {
    transform: scale(0.7);
  }
  @media (max-width: 1000px) {
    transform: scale(0.65);
  }
  @media (max-width: 750px) {
    transform: scale(0.55);
  }
`;

const useStyles = makeStyles({
  customAccordion: {
    "&.MuiExpansionPanel-root:before": {
      display: "none",
      backgroundColor: "transparent !important",
      height: 0,
      fontFamily: "axiforma-light",
    },
    "&.MuiExpansionPanel-root": {
      fontFamily: "axiforma-light",
    },
  },
});

const Oferty = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <OfertyContainer id="oferta">
      <Left>
        <OfertyTitle>
          Ofe<Italic style={{ marginRight: "5px" }}>r</Italic>ta
        </OfertyTitle>
        <OfertySubtitle>
          Połączenie rzemieślniczej ręki i światowej mody
        </OfertySubtitle>
        <SmallText>
          Dobranie oprawek, badanie wzroku, wybór soczewek… W salonie Wolnik
          Optyk zajmiemy się kompleksowo komfortem Twojego widzenia.
        </SmallText>
        <Devider></Devider>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{
            backgroundColor: "transparent",
            border: 0,
            outline: 0,
            boxShadow: "none",
          }}
          sx={{
            "&:before": {
              boxShadow: "none",
              backgroundColor: "#3E465B",
              color: "white",
              height: "0px",
              border: 0,
            },
          }}
          className={classes.customAccordion}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                style={{
                  color: expanded === "panel1" ? "#e88565" : "#011638",
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                color: expanded === "panel1" ? "#e88565" : "#011638",
                fontWeight: "bold",
                fontFamily: "axiforma-light",
              }}
            >
              Okulary progresywne
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "axiforma-light",
              }}
            >
              Potrzebujesz okularów na blisko i na daleko? Postaw na jedne
              okulary, z soczewkami progresywnymi. Dzięki kilku strefom widzenia
              okulary progresywne pozwolą Ci czuć się pewnie w każdej sytuacji.
              To wygoda, nowoczesność i komfort na miarę naszych czasów.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          style={{
            backgroundColor: "transparent",
            border: 0,
            outline: 0,
            boxShadow: "none",
          }}
          sx={{
            "&:before": {
              boxShadow: "none",
              backgroundColor: "#3E465B",
              color: "white",
              height: "0px",
              border: 0,
            },
          }}
          className={classes.customAccordion}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                style={{
                  color: expanded === "panel2" ? "#e88565" : "#011638",
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                color: expanded === "panel2" ? "#e88565" : "#011638",
                fontWeight: "bold",
                fontFamily: "axiforma-light",
              }}
            >
              Okulary przeciwsłoneczne
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "axiforma-light",
              }}
            >
              Co jest modne każdego lata? Jakość! Postaw na okulary
              przeciwsłoneczne, które podkreślą Twój styl i skutecznie ochronią
              Twoje oczy. Wysoka ochrona UV i dobra polaryzacja poprawiająca
              komfort widzenia to inwestycja na lata.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          style={{
            backgroundColor: "transparent",
            border: 0,
            outline: 0,
            boxShadow: "none",
          }}
          sx={{
            "&:before": {
              boxShadow: "none",
              backgroundColor: "#3E465B",
              color: "white",
              height: "0px",
              border: 0,
            },
          }}
          className={classes.customAccordion}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                style={{
                  color: expanded === "panel3" ? "#e88565" : "#011638",
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                color: expanded === "panel3" ? "#e88565" : "#011638",
                fontWeight: "bold",
                fontFamily: "axiforma-light",
              }}
            >
              Badanie wzroku
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "axiforma-light",
              }}
            >
              Za małe litery w filmie, dyskomfort podczas wieczornej jazdy,
              zmęczenie oczu po pracy przy komputerze… Widzisz problem? W
              salonie Wolnik Optyk zadbamy o Twój wzrok nowoczesnym sprzętem.
              Szybko, komfortowo i bezpłatnie w przypadku zakupu okularów
              korekcyjnych.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          style={{
            backgroundColor: "transparent",
            border: 0,
            outline: 0,
            boxShadow: "none",
          }}
          sx={{
            "&:before": {
              boxShadow: "none",
              backgroundColor: "#3E465B",
              color: "white",
              height: "0px",
              border: 0,
            },
          }}
          className={classes.customAccordion}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                style={{
                  color: expanded === "panel4" ? "#e88565" : "#011638",
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                color: expanded === "panel4" ? "#e88565" : "#011638",
                fontWeight: "bold",
                fontFamily: "axiforma-light",
              }}
            >
              Okulary korekcyjne
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "axiforma-light",
              }}
            >
              Okulary korekcyjne nie muszą być przykrym obowiązkiem. W salonie
              Wolnik Optyk pomożemy Ci dobrać takie, które będą codzienną
              biżuterią i ozdobą Twojej twarzy. Podkreśl swój styl, poczuj się
              pewnie i zakochaj się w swoich nowych okularach.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          style={{
            backgroundColor: "transparent",
            border: 0,
            outline: 0,
            boxShadow: "none",
          }}
          sx={{
            "&:before": {
              boxShadow: "none",
              backgroundColor: "#3E465B",
              color: "white",
              height: "0px",
              border: 0,
            },
          }}
          className={classes.customAccordion}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                style={{
                  color: expanded === "panel5" ? "#e88565" : "#011638",
                }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                color: expanded === "panel5" ? "#e88565" : "#011638",
                fontWeight: "bold",
                fontFamily: "axiforma-light",
              }}
            >
              Naprawa okularów
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "axiforma-light",
              }}
            >
              Masz już swoje nowe okulary? Gratulacje! Teraz pomożemy Ci o nie
              zadbać. W razie potrzeby dopasujemy je, wyczyścimy i naprawimy.
              Możesz na nas liczyć!
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Left>
      <Right>
        <TransformScale>
          <BlurredImage src={girlImage} width={370} height={560} />{" "}
        </TransformScale>
      </Right>
    </OfertyContainer>
  );
};

export default Oferty;
