import React from "react";
import Carousel from "coomponents/LogoCarousel/Carousel";
import styled from "styled-components";
const bossImage = require("./../../assets/stary1.jpg");
const okularkiImage = require("./../../assets/okularki.png");

export const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 110vh;
  position: relative;
  // border: 1px solid red;

  @media (min-width: 650px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
  }
  @media (max-height: 740px) and (min-width: 1300px) {
    height: 100vh;
  }
  @media (max-height: 750px) and (min-width: 800px) {
    height: 100vh;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  position: absolute;
  left: 10%;
  top: 140px;
  @media (max-height: 740px) and (min-width: 1300px) {
    top: 60px;
  }
  @media (max-height: 750px) and (min-width: 800px) {
    top: 60px;
  }
  @media (min-width: 1600px) {
    font-size: 5vw;
  }
  @media (min-width: 650px) {
    align-items: flex-start;
    text-align: left;
    width: 400px;
    height: 600px;
    padding-right: 2rem;
    font-size: 10vw;
  }
  @media (max-width: 650px) {
    left: 0;
    top: 0;
  }
`;

export const Heading = styled.h1`
  color: #011638;
  font-size: 120px;
  font-weight: 400;
  letter-spacing: -0.28231rem;
  margin-bottom: 14px;
  line-height: 80%;
  // mix-blend-mode: difference;
  @media (max-width: 910px) {
    font-size: 110px;
  }
  @media (max-width: 860px) {
    font-size: 100px;
  }
  @media (max-width: 680px) {
    font-size: 90px;
  }
  @media (max-width: 650px) {
    font-size: 110px;
    width: 90vw;
    text-align: left;
    margin-left: 30px;
    margin-top: 20px;
    position: relative;
    z-index: 1000;
    overflow: hidden;
    position: absolute;
  }
  @media (max-width: 450px) {
    font-size: 110px;
    width: 90vw;
    text-align: left;
    margin-left: 30px;
    margin-top: 20px;
    position: relative;
    z-index: 1000;
    overflow: hidden;
    position: absolute;
  }
  @media (max-width: 410px) {
    font-size: 95px;
    width: 90vw;
    text-align: left;
    margin-left: 30px;
    margin-top: 50px;
    position: relative;
    z-index: 1000;
    overflow: hidden;
    position: absolute;
  }

  @media (min-width: 1300px) {
    margin-left: 70px;
  }

  @media (min-width: 1400px) {
    font-size: 120px;
    margin-top: 60px;
  }
`;

export const HeadingTwo = styled.h1`
  color: #e88565;
  font-size: 120px;
  position: absolute;
  font-weight: 400;
  letter-spacing: -0.28231rem;
  margin-bottom: 8px;
  line-height: 80%;
  @media (min-width: 651px) {
    display: none !important;
  }
  @media (max-width: 650px) {
    font-size: 110px;
    width: 90vw;
    text-align: left;
    margin-left: 30px;
    margin-top: 20px;
    z-index: 1000;

    position: relative;
  }
  @media (max-width: 450px) {
    font-size: 110px;
    width: 90vw;
    text-align: left;
    margin-left: 30px;
    margin-top: 20px;
    position: relative;
    z-index: 1000;
  }
  @media (max-width: 410px) {
    font-size: 95px;
    width: 90vw;
    text-align: left;
    margin-left: 30px;
    margin-top: 50px;
    position: relative;
    z-index: 1000;
  }
`;

// Add a new class for the right-aligned text
export const HeadingRight = styled(Heading)`
  text-align: right; /* Right-align the text */

  mask-image: linear-gradient(#e88565, #e88565 80%, #1e2d46 80%, #1e2d46);
`;

export const Subtext = styled.h2`
  color: #1e2d46;
  font-family: "axiforma-light";
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.03438rem;
  width: 110%;
  @media (max-width: 450px) {
    font-size: 4vw;
    position: absolute;
    top: 580px;
    text-align: left;
    width: 80%;
  }
  @media (max-width: 650px) {
    font-size: 4vw;
    position: absolute;
    top: 650px;
    text-align: left;
    width: 80%;
  }
  @media (min-width: 1200px) {
    font-size: 17px;
  }
  @media (min-width: 1300px) {
    margin-left: 70px;
  }
  @media (max-width: 910px) {
    font-size:15px;
    width:100%;
  }
  @media (max-width: 860px) {
width:80%;  }

}`;

export const SubtextTwo = styled.h3`
  color: #1e2d46;
  font-family: "axiforma-light";
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: -14px;

  letter-spacing: 0.03438rem;
  width: 100%;
  @media (max-width: 450px) {
    font-size: 4vw;
    position: absolute;
    top: 690px;
    text-align: left;
    width: 80%;
  }
  @media (max-width: 650px) {
    font-size: 4vw;
    position: absolute;
    top: 750px;
    text-align: left;
    width: 80%;
  }
  @media (min-width: 1200px) {
    font-size: 17px;
  }
  @media (min-width: 1300px) {
    margin-left: 70px;
  }
  @media (max-width: 910px) {
    font-size:15px;
    width:100%;
  }
  @media (max-width: 860px) {
    width:80%;  }
}`;

export const ColorfulDiv = styled.div`
  width: 70vw;
  height: 360px;
  background: #e88565;
  position: absolute;
  right: 50px;
  top: 187px;
  z-index: -2;
  @media (max-width: 650px) {
    width: 70vw;
    height: 170px;
    top: 350px;
  }
  @media (max-height: 740px) and (min-width: 1300px) {
    top: 147px;
  }
  @media (max-height: 750px) and (min-width: 800px) {
    top: 147px;
  }
`;

export const Image2 = styled.img`
  position: absolute;
  top: 350px;
  right: 45%;
  @media (max-height: 740px) and (min-width: 1300px) {
    top: 290px;
  }
  @media (max-height: 750px) and (min-width: 800px) {
    top: 290px;
  }
  @media (max-width: 1200px) {
    right: 40%;
  }
  @media (max-width: 1020px) {
    display: none;
  }
  @media (min-width: 1150px) {
    right: 43%;
    top: 370px;
  }
  @media (min-width: 1150px) {
    right: 43%;
    top: 370px;
  }
  @media (max-width: 1400px) {
display:none;  }
  @media (min-width: 1400px) {
    right: 45%;
    top: 400px;
  }
  @media (min-width: 1600px) {
    right: 50%;
    top: 400px;
  }
}`;

export const Image = styled.img`
  width: 39.29175rem;
  height: 39.29175rem;
  position: absolute;
  top: 120px;
  right: 10%;
  object-fit: cover;
  border-radius: 12px;
  background: lightgray 0px -130.313px / 100% 123.688% no-repeat;

  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.07),
    0px 4px 8px 0px rgba(0, 0, 0, 0.07), 0px 14px 14px 0px rgba(0, 0, 0, 0.06),
    0px 32px 19px 0px rgba(0, 0, 0, 0.04), 0px 57px 23px 0px rgba(0, 0, 0, 0.01),
    0px 89px 25px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px inset linear-gradient(45deg, #ffffff, #2d2d2d);
  @media (max-height: 740px) and (min-width: 1300px) {
    top: 100px;
  }
  @media (max-height: 750px) and (min-width: 800px) {
    top: 100px;
  }
  @media (min-width: 1200px) {
    width: 25rem;
    height: 35rem;
  }
  @media (min-width: 650px) {
    width: 16rem;
    height: 30rem;
  }
  @media (min-width: 830px) {
    width: 18rem;
    height: 30rem;
  }
  @media (max-width: 450px) {
    width: 50%;
    height: 300px;
    top: 265px;
    left: 20px;
    z-index: 100;
  }
  @media (max-width: 650px) {
    width: 60%;
    height: 320px;
    top: 285px;
    left: 20px;
    z-index: 100;
  }
  @media (min-width: 1300px) {
    right: 10%;
    width: 30rem;
    height: 35rem;
  }
  @media (max-width: 740px) {
    right: 20px;
  }
`;

export const Italic = styled.span`
  font-family: "CustomFontRegularItalic";
  // margin-right: 9px;
`;

const LandingPage = () => {
  return (
    <LandingPageContainer id="home">
      <TextContainer>
        <HeadingTwo>
          <Italic>Sa</Italic>lon mistrza opty
          <Italic>ki</Italic>
        </HeadingTwo>
        <Heading>
          <Italic>Sa</Italic>lon mistrza opty
          <Italic>ki</Italic>
        </Heading>
        <Subtext>
          Od 40 lat dobieram wyjątkowe oprawy, badam wzrok i dopasowuje
          najlepsze dostępne soczewki.
        </Subtext>
        <SubtextTwo>
          Nasze okulary, to dalekowzroczna inwestycja w zdrowie i pewność
          siebie. Zobacz różnicę na pierwszy rzut oka.
        </SubtextTwo>
      </TextContainer>
      <ColorfulDiv />
      <Image2 src={okularkiImage} alt="okularki" />
      <Image src={bossImage} alt="The Boss" />
      <Carousel />
    </LandingPageContainer>
  );
};

export default LandingPage;
